import { defineStore } from 'pinia'

export const useOptionsStore = defineStore({
  
  id: 'options-store',
  persist: true,

  state: () => {
    return {
      registrationsEnabled: false,
      registrationsTest: false,
      sidebarCollapsed: false,
      breadcrumbs: []
    }
  },

  actions: {

    updateOptions(data) {
      this.registrationsEnabled = data.allow_registrations || false
      this.registrationsTest = data.allow_registrations_test || false
    },

    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed
    },

    setBreadcrumbs(data) {
      this.breadcrumbs = data
    }

  },

  getters: {
    registrationsOpen: (state) => state.registrationsEnabled,
    registrationsOpenTest: (state) => {
      if (state.registrationsTest && process.client) {
        if (location.host === 'localhost:3000') return true
      }
      return false
    }
  },
  
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOptionsStore, import.meta.hot))
}